import React, { useEffect, useState } from 'react';
import { db, auth } from '../../firebase.config'; // Import db and auth from firebase.config
import { collection, getDocs, deleteDoc, doc, query, where } from 'firebase/firestore'; // Import collection, getDocs, deleteDoc, query, where

const ManageWarehouse = () => {
    const [warehouses, setWarehouses] = useState([]);
    const currentUser = auth.currentUser;

    useEffect(() => {
        // Fetch warehouses when the component mounts
        if (currentUser) {
            fetchWarehouses();
        }
    }, [currentUser]);

    const fetchWarehouses = async () => {
        try {
            const q = query(collection(db, 'warehouses'), where('user_id', '==', currentUser.uid));
            const querySnapshot = await getDocs(q);
            const warehouseList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setWarehouses(warehouseList);
        } catch (error) {
            console.error('Error fetching warehouses:', error);
        }
    };

    const deleteWarehouse = async (id) => {
        try {
            await deleteDoc(doc(db, 'warehouses', id)); // Use doc function to get reference
            fetchWarehouses(); // Fetch updated list after deletion
        } catch (error) {
            console.error('Error deleting warehouse:', error);
        }
    };

    return (
        <div>
            <div className='mt-5 overflow-x-auto shadow-md sm:rounded-lg border border-black'>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-800 dark:text-gray-400">
                            <tr>
                                <th className="px-6 py-3">Warehouse Details</th>
                                <th className="px-6 py-3">Address</th>
                                <th className="px-6 py-3">City</th>
                                <th className="px-6 py-3">State</th>
                                <th className="px-6 py-3">Country</th>
                                <th className="px-6 py-3">Pincode</th>
                                <th className="px-6 py-3">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {warehouses.map((warehouse) => (
                                <tr key={warehouse.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="px-6 py-4 text-black">
                                        <div>
                                            <span>{warehouse.name}</span><br />
                                            <span>{warehouse.email}</span><br />
                                            <span>{warehouse.phone}</span>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 text-black">{warehouse.address}</td>
                                    <td className="px-6 py-4 text-black">{warehouse.city}</td>
                                    <td className="px-6 py-4 text-black">{warehouse.state}</td>
                                    <td className="px-6 py-4 text-black">{warehouse.country}</td>
                                    <td className="px-6 py-4 text-black">{warehouse.pin_code}</td>
                                    <td className="px-6 py-4 text-black">
                                        <button type="button" class="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg" onClick={() => deleteWarehouse(warehouse.id)}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default ManageWarehouse;
