import React, { useEffect, useState } from 'react';
import Navbar from "../../common/Navbar";
import Addsingleorder from './Addsingleorder';
import Addheavyorder from './Addheavyorder';
import { useNavigate } from 'react-router-dom';

function Orders() {

    const nonActiveClass = "px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:text-blue-700 focus:z-10 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white";
    const activeClass = "bg-blue-700 px-4 py-2 text-sm font-medium text-white border border-gray-200 rounded-lg focus:z-10 dark:bg-blue-500 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white";

    const [orderType, setOrderType] = useState(0);

    const navigate = useNavigate();

    useEffect(() => {
        const user = window.localStorage.getItem('umaxshipuser');
        if (user === null || user === undefined || user === '') {
            navigate('/login/')
        }
    }, [])

    return (
        <div>
            <Navbar />

            <div class="inline-flex rounded-md shadow-sm mt-5 gap-5" role="group">
                <button type="button" class={orderType === 0 ? activeClass : nonActiveClass} onClick={() => setOrderType(0)}>
                    Add Single Orders (B2C)
                </button>
                <button type="button" class={orderType === 1 ? activeClass : nonActiveClass} onClick={() => setOrderType(1)}>
                    Add Heavy Orders (B2B)
                </button>
            </div>

            {
                orderType === 0 &&
                <Addsingleorder />
            }

            {
                orderType === 1 &&
                <Addheavyorder />
            }
        </div>
    )
}

export default Orders