import { Outlet } from "react-router-dom";
import { Sidebar } from "../components";
import { SidebarProvider } from "../context/SidebarContext";

const BaseLayout = () => {
  return (
    <main className="page-wrapper">
      {/* left of page */}
      <SidebarProvider>
        <Sidebar />
      </SidebarProvider>
      {/* right side/content of the page */}
      <div className="content-wrapper">
        <Outlet />
      </div>
    </main>
  );
};

export default BaseLayout;
