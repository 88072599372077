import React, { useEffect, useState } from 'react';
import Navbar from '../../common/Navbar'
import { useNavigate } from 'react-router-dom';
import RaisedComplaints from './RaisedComplaints';
import Complaints from './Complaints';

const CustomerSupport = () => {
    const nonActiveClass = "px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:text-blue-700 focus:z-10 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white";
    const activeClass = "bg-blue-700 px-4 py-2 text-sm font-medium text-white border border-gray-200 rounded-lg focus:z-10 dark:bg-blue-500 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white";

    const [ComplaintsType, setComplaintsType] = useState(0);

    useEffect(() => {
        const user = window.localStorage.getItem('umaxshipuser');
        if (user === null || user === undefined || user === '') {
            navigate('/login/')
        }
    }, [])

    const navigate = useNavigate();
    return (
        <div>
            <Navbar />

            <div class="inline-flex rounded-md shadow-sm mt-5 gap-5" role="group">
                <button type="button" class={ComplaintsType === 0 ? activeClass : nonActiveClass} onClick={() => setComplaintsType(0)}>
                    Raised Complaints
                </button>
                <button type="button" class={ComplaintsType === 1 ? activeClass : nonActiveClass} onClick={() => setComplaintsType(1)}>
                    Complaints
                </button>
            </div>

            {
                ComplaintsType === 0 &&
                <RaisedComplaints />
            }
            {
                ComplaintsType === 1 &&
                <Complaints />
            }
        </div>
    )
}

export default CustomerSupport