import React, { useEffect, useState } from 'react';
import ApexCharts from "apexcharts";
import { db } from '../../firebase.config';
import { query, getDocs, collection, where } from 'firebase/firestore';

function Shippingchart() {

    const [userId, setUserId] = useState('');

    useEffect(() => {
        const currentUser = localStorage.getItem('umaxshipuser');
        const jsonData = JSON.parse(currentUser);
        if (jsonData) {
            setUserId(jsonData.uid);
        }
    }, [])

    const [ordersList, setOrdersList] = useState([]);

    const fetchOrders = async () => {
        try {
            const q = query(collection(db, "orders"), where("user_id", "==", userId));
            const querySnapshot = await getDocs(q);

            let orders = []; // Initialize empty array to store valid orders

            querySnapshot.forEach((doc) => {
                const docData = doc.data();
                orders.push({ ...docData, id: doc.id });
            });

            setOrdersList(orders);
        } catch (error) {
            console.error("Error fetching orders:", error);
            // Handle errors appropriately (e.g., display error message to user)
        }
    };

    useEffect(() => {
        fetchOrders();
    }, [userId])

    const [unshipped, setUnshipped] = useState(1);
    const [readyToShip, setReadyToShip] = useState(1);
    const [pickupScheduled, setPickupScheduled] = useState(1);
    const [menifested, setMenifested] = useState(1);
    const [pickupException, setPickupException] = useState(1);
    const [shipped, setShipped] = useState(1);
    const [inTransit, setInTransit] = useState(1);
    const [delivered, setDelivered] = useState(1);

    function countUnshippedOrders(ordersList) {
        const unshippedOrders = ordersList.filter(item => item.current_status === "UNSHIPPED");
        return unshippedOrders.length;
    }

    function countReadyToShip(ordersList) {
        const unshippedOrders = ordersList.filter(item => item.current_status === "READY TO SHIP");
        return unshippedOrders.length;
    }

    function countPickupScheduled(ordersList) {
        const unshippedOrders = ordersList.filter(item => item.current_status === "PICKUP SCHEDULED");
        return unshippedOrders.length;
    }

    function countMenifested(ordersList) {
        const unshippedOrders = ordersList.filter(item => item.current_status === "MANIFESTED");
        return unshippedOrders.length;
    }

    function countPickupException(ordersList) {
        const unshippedOrders = ordersList.filter(item => item.current_status === "PICKUP EXCEPTION");
        return unshippedOrders.length;
    }

    function countShipped(ordersList) {
        const unshippedOrders = ordersList.filter(item => item.current_status === "SHIPPED");
        return unshippedOrders.length;
    }

    function countIntransit(ordersList) {
        const unshippedOrders = ordersList.filter(item => item.current_status === "IN TRANSIT");
        return unshippedOrders.length;
    }

    function countDelivered(ordersList) {
        const unshippedOrders = ordersList.filter(item => item.current_status === "IN TRANSIT");
        return unshippedOrders.length;
    }

    useEffect(() => {
        if (ordersList.length > 0) {
            setUnshipped(countUnshippedOrders(ordersList))
            setReadyToShip(countReadyToShip(ordersList))
            setPickupScheduled(countPickupScheduled(ordersList))
            setMenifested(countMenifested(ordersList))
            setPickupException(countPickupException(ordersList))
            setShipped(countShipped(ordersList))
            setInTransit(countIntransit(ordersList))
            setDelivered(countDelivered(ordersList))
            console.log(countIntransit(ordersList))
        }
    }, [ordersList]);

    const getChartOptions = () => {
        return {
            series: [unshipped, readyToShip, pickupScheduled, menifested, inTransit, delivered, pickupException, 0, 0, shipped],
            colors: ["#1C64F2", "#16BDCA", "#FDBA8C", "#16BDCA", "#E74694", "#fb8500", "#ff006e", "#724cf9", "#fe6d73", "#E74694"],
            chart: {
                height: 320,
                width: "100%",
                type: "donut",
            },
            stroke: {
                colors: ["transparent"],
                lineCap: "",
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontFamily: "Inter, sans-serif",
                                offsetY: 20,
                                color: '#FFFFFF'
                            },
                            total: {
                                showAlways: true,
                                show: true,
                                label: "Total Shipments",
                                fontFamily: "Inter, sans-serif",
                                color: '#FFFFFF',
                                formatter: function () {
                                    return ordersList.length
                                },
                            },
                            value: {
                                show: true,
                                fontFamily: "Inter, sans-serif",
                                offsetY: -20,
                                formatter: function (value) {
                                    return value
                                },
                            },
                        },
                        size: "80%",
                    },
                },
            },
            grid: {
                padding: {
                    top: -2,
                },
            },
            labels: ["Unshipped", "Ready to ship", "Scheduled Pickup", "Manifested", "In-Transit", "Delivered", "Pickup Exception", "RTO In-Transit", "RTO Delivered", "Shipped"],
            dataLabels: {
                enabled: false,
            },
            legend: {
                position: "bottom",
                fontFamily: "Inter, sans-serif",
                color: 'white'
            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        return value
                    },
                },
            },
            xaxis: {
                labels: {
                    formatter: function (value) {
                        return value
                    },
                },
                axisTicks: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                },
            },
        }
    }

    useEffect(() => {
        let chartRendered = false; // Flag to track if the chart has been rendered

        const chart = new ApexCharts(document.getElementById("donut-chart"), getChartOptions());

        if (document.getElementById("donut-chart") && typeof ApexCharts !== 'undefined') {
            if (!chartRendered) {
                chart.render();
                chartRendered = true; // Mark as rendered
            }
        }

        return () => {
            if (chartRendered) {
                // Assuming ApexCharts has a destroy method
                chart.destroy();
            }
        };
    }, [delivered, unshipped]);

    return (
        <div className="flex mt-5">
            <div class="max-w-sm w-full bg-[#003B49] rounded-lg shadow p-4 md:p-6">

                <div class="flex justify-between mb-3">
                    <div class="flex justify-center items-center">
                        <h5 class="text-xl font-bold leading-none text-white pe-1">Shipment Details</h5>
                    </div>
                </div>

                <div class="py-6" id="donut-chart">
                </div>

                <div class="grid grid-cols-1 items-center border-gray-200 border-t dark:border-gray-700 justify-between">
                    <div class="flex justify-between items-center pt-5">
                        <button
                            id="dropdownDefaultButton"
                            class="text-sm font-medium text-white text-center inline-flex items-center"
                            type="button">
                            All time
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Shippingchart